import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[domain]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/public/favicon.png");
;
import(/* webpackMode: "eager", webpackExports: ["AxiosInstanceProvider"] */ "/usr/src/app/src/config/axios.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/config/CountryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/config/EnvVariablesProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/config/MuiProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/config/Notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/config/QueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/styles/globals.css");
